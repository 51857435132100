<template lang="pug">
v-card(v-if="statement && Object.keys(statement || {}).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div.text-left {{ $t(`${Object.keys(statement?.behavior || {})[0]}-${type}`, { number: statement.number }).toUpperCase() }}
      span(v-if="checkAccess('backOffice')").ml-1 (ID: {{ statement.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    Info(v-if="statement?.behavior?.viewInfoBlock" :statement="statement" :comments="comments")
    FormEdit(v-else-if="statement?.behavior?.viewEditBlock" :statement="statement" actionType="edit")
    EditStatus(v-else-if="statement?.behavior?.viewEditStatusBlock" :statement="statement")
    ViewPhotoList(
      v-else-if="statement?.behavior?.viewFilesBlock"
      :files="statement?.files"
      :sailorDocument="statement"
      :documentType="type"
      :deleteFile="removeFileCertificateStatement"
      :editFile="updateFileCertificateStatementETI"
      isShowEdit
      )
v-progress-linear(v-else indeterminate)
</template>

<script>

import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapGetters, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorCertificationStatement',
  components: {
    Info: () => import('@/views/Sailor/SailorCertificationStatement/Info.vue'),
    FormEdit: () => import('@/views/Sailor/SailorCertificationStatement/Form.vue'),
    EditStatus: () => import('@/views/Sailor/SailorCertificationStatement/EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      sendToInspection: true,
      type: 'certificationStatement',
      comments: [],
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        // {
        //   id: 7,
        //   name: 'mdi-email-fast-outline',
        //   action: () => this.mailInspection(),
        //   checkAccess: () => checkAccess('backOffice') && this.sendToInspection && (this.statement.status_document?.id || this.statement.status_document) === 61,
        //   color: '#42627e',
        //   tooltip: 'documentIconControl.mailInspection',
        //   buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        // },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.statement, 'viewEditBlock'),
          checkAccess: () => checkAccess('certificationStatement', 'edit', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.statement, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('certificationStatement', 'editStatus', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.statement, 'viewFilesBlock'),
          checkAccess: () => checkAccess('certificationStatement', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('certificationStatement', 'delete', this.statement),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => back('certification-statements'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['statementByID']),
    ...mapState({
      statement: state => state.sailor.certificationStatementById
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getCertificateStatements', 'getCertificateStatementById', 'sendToETICertificateStatements',
      'removeCertificateStatement', 'getCertificateStatementsComment', 'removeFileCertificateStatement',
      'updateFileCertificateStatementETI']),

    async getData () {
      await this.getCertificateStatementById({ ...this.$route.params })
    },
    mailInspection () {
      if (this.statement.id) {
        this.sendToETICertificateStatements({ ...this.$route.params }).then(response => {
          if (response?.code === 200) {
            this.$notification.success('sendToInspection')
            this.sendToInspection = false
          }
        })
      }
    },

    deleteDocument () {
      deleteConfirmation().then(async confirmation => {
        if (confirmation) {
          const response = await this.removeCertificateStatement({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response?.code)) {
            this.$notification.success('deletedDocument')
            back('certification-statements')
          }
        }
      })
    }
  }
}
</script>
